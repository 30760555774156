<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 2.75C8.5 2.0601 9.0601 1.5 9.75 1.5H11.25C11.9399 1.5 12.5 2.0601 12.5 2.75V19.25C12.5 19.9399 11.9399 20.5 11.25 20.5H9.75C9.0601 20.5 8.5 19.9399 8.5 19.25V2.75ZM1 11.75C1 11.0601 1.5601 10.5 2.25 10.5H3.75C4.4399 10.5 5 11.0601 5 11.75V19.25C5 19.9399 4.4399 20.5 3.75 20.5H2.25C1.5601 20.5 1 19.9399 1 19.25V11.75ZM17.25 4.5H18.75C19.4399 4.5 20 5.0601 20 5.75V19.25C20 19.9399 19.4399 20.5 18.75 20.5H17.25C16.5601 20.5 16 19.9399 16 19.25V5.75C16 5.0601 16.5601 4.5 17.25 4.5Z"
      stroke="url(#paint0_linear_5299_1015)"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5299_1015"
        x1="10.35"
        y1="-2.86"
        x2="10.35"
        y2="24.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="sass"></style>
